header {
  line-height: 0;
  overflow: hidden;
  position: relative;
}


header img {
  width: 100%;

  @media (max-width: $screen-sm-min) {
    transform: translateX(-25%);
    width: 200%;
  }
}


header .container {
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-20%);

  .text_partial {
    padding: 15px !important;
  }
}


.header-image__placeholder {
  background-color: $gray-light;
  height: 250px;
}