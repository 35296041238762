.labels {
  margin: {
    top: 14rem;
    bottom: 2rem;
  }
}


.label {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  font-size: $font-size-base;
  margin-right: 5px;
  outline: 0 !important;
  padding: 6px 12px;

  &:hover,
  &-primary {
    border-bottom-color: $brand-primary;
  }
}


.label-container,
.label-new-container {
  margin-bottom: 0.5rem;

  .form-control {
    display: inline-block;
    width: unset;
  }
}


.label-new-container {
  margin-top: 4rem;
}


.label-confirm {
  display: none;

  > * {
    display: inline-block;
  }
}


.labels-save {
  width: 184px;
}