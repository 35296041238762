.search-form {
  display: inline-block;
  margin-top: 2.2rem;
  transition: margin 400ms ease-in-out;

  input {
    border: 1px solid $gray-lightest;
    border-radius: 4px;
  }
}

.navbar-inverse .search-form {
  margin-top: 4.7rem;
}

.voog-search-modal {
  z-index: 2000;
}