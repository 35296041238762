/* Colors */

$gray-base: #000;
$gray-dark: lighten($gray-base, 20%);
$gray-light: #888;
$gray-lightest: #d7dfe2;

$brand-primary: #fd8000;
$brand-success: #89c831;


/* Tables */

$table-bg-accent: $gray-lightest;


/* Scaffolding */

$text-color: $gray-dark;


/* Media queries breakpoints */

$screen-md: 992px;
$screen-md-min: $screen-md;


/* Grid system */

$grid-float-breakpoint: $screen-md-min;


/* Navbar */

$navbar-height: 70px;

$navbar-default-bg: rgba(255, 255, 255, 0.9);
$navbar-default-border: $gray-light;

$navbar-default-link-color: $text-color;
$navbar-default-link-hover-color: $brand-primary;
$navbar-default-link-active-color: $navbar-default-link-hover-color;
$navbar-default-link-active-bg: transparent;

$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-icon-bar-bg: $gray-light;

$navbar-inverse-bg: $navbar-default-bg;

$navbar-inverse-link-color: white;
$navbar-inverse-link-hover-color: $brand-primary;
$navbar-inverse-link-active-bg: transparent;

$navbar-inverse-toggle-hover-bg: transparent;


/* Navs */

$nav-link-padding-vertical: 10px;
$nav-link-padding-horizontal: 15px;
$nav-link-padding: $nav-link-padding-vertical $nav-link-padding-horizontal;
$nav-link-hover-bg: transparent;


/* Tabs */

$nav-tabs-border-color: $gray-light;

$nav-tabs-active-link-hover-color: $text-color;
$nav-tabs-active-link-hover-border-color: $brand-primary;


/* Labels */

$label-color: $text-color;