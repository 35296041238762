video {
  width: 100%;
}


.video-mobile {
  height: 100vh;
  margin-left: 50%;
  transform: translateX(-50%);
  width: unset;
}


.video__select {
  margin-bottom: 3rem;

  &:first-of-type {
    margin-top: 200px;
  }
}