$product-width: 200px;
$product-height: $product-width;
$product-gap: $product-width * 0.15;
$product-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 3px 1px -2px rgba(0, 0, 0, 0.22), 0 1px 5px 0 rgba(0, 0, 0, 0.3);
$product-color: white;
$product-border-radius: 2px;
$product-title-height: 55px;


.products-container,
.product-filters {
  margin: $product-gap 0;
}


.products-container {
  display: grid;
  grid-gap: $product-gap;
  grid-template-columns: repeat(auto-fill, minmax($product-width, 1fr));

  @media (min-width: $screen-sm-min) {
    grid-gap: $product-gap * 1.5;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .products-container {
    display: block;
  }
}


.product {
  border-radius: $product-border-radius;
  box-shadow: $product-shadow;
  cursor: pointer;
  height: $product-height;
  position: relative;
  overflow: hidden;

  &,
  &:hover,
  &:focus {
    color: $product-color;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .product {
    display: inline-block;
    margin-bottom: $product-gap;
    margin-right: $product-gap;
    width: 22%;
  }
}


.product-image {
  background: {
    color: $gray-lighter;
    position: 50% 50%;
    repeat: no-repeat;
    size: cover;
  }
  border-radius: $product-border-radius;
  height: 100%;
  transition: transform 400ms ease-in-out;
  width: 100%;

  .product:hover & {
    transform: scale(1.1);
  }
}


.product-title,
.product-excerpt {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 $product-border-radius $product-border-radius;
  left: 0;
  padding: 0 1.5rem;
  position: absolute;
  right: 0;
  transition: top 200ms ease-out;
  z-index: 1;
}


.product-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0.5px;
  height: $product-title-height;
  text-shadow: 1px 1px 1px $text-color;
  top: $product-height - $product-title-height;

  .product:hover & {
    border-radius: $product-border-radius $product-border-radius 0 0;
    top: 0;
  }

  h4 {
    margin: 0;
  }
}


.product-excerpt {
  font: {
    family: "Oswald", sans-serif;
    weight: 300;
  }
  height: $product-height - $product-title-height;
  letter-spacing: 0.8px;
  top: $product-height;

  .product:hover & {
    top: $product-title-height;
  }
}


.product-icon-image {
  background-size: contain;
  border: 2px solid white;
  border-radius: 10px;
  height: 50px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 50px;
}


.product-icon-virtuosity {
  left: 6rem;
  position: absolute;
  top: 5px;
  width: 50px;
}


.product-header {
  letter-spacing: 0.5px;
}


.product-category-select,
.product-label-select,
.product-icon-select {
  display: inline-block;
  width: unset;
}


.product-icon {
  display: inline-block;
  margin-right: 1rem;
  width: 70px;
}


.add-product-button .edy-cbtn {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: $product-border-radius;
  box-shadow: $product-shadow !important;
  height: $product-height;
  transition: background-color 200ms ease-in-out;
  width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }

  .edy-cbtn-content {
    padding-top: 10px !important;

    &:before {
      content: '+';
      font-size: 5rem;
      transition: opacity 200ms ease-in-out;
    }
  }

  .edy-cbtn-ico,
  .edy-cbtn-text,
  > span:before {
    display: none !important;
  }
}

.meta-description-length {
  font-size: 1.2rem;
}
