$news-width: 250px;
$news-height: $news-width;
$news-gap: $news-width * 0.15;
$news-title-height: 85px;


.products-container.news-container {
  grid-gap: $news-gap;
  grid-template-columns: repeat(auto-fill, minmax($news-width, 1fr));

  @media (min-width: $screen-sm-min) {
    grid-gap: $news-gap * 1.5;
  }
}


.product.news {
  cursor: pointer;
  height: $news-height;
}


.product-title.news-title {
  height: $news-title-height;
  top: $news-height - $news-title-height;
}


.product-excerpt.news-excerpt {
  height: $news-height - $news-title-height;
  top: $news-height;

  .product.news:hover & {
    top: $news-title-height;
  }
}


.add-product-button.add-news-button .edy-cbtn {
  height: $news-height;
}