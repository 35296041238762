$navbar-default-logo-height: 40px;
$navbar-inverse-height: 120px;
$navbar-inverse-logo-height: 55px;
$navbar-toggle-height: 55px;
$navbar-transition: 400ms ease-in-out;


.navbar {
  border-bottom-width: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  transition: background $navbar-transition, min-height $navbar-transition;

  &.navbar-inverse {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    box-shadow: none;
    text-shadow: 1px 1px 1px $text-color;

    @media (min-width: $screen-sm-min) {
      min-height: $navbar-inverse-height;
    }
  }
}


.navbar-nav a {
  font: {
    family: "Oswald", sans-serif;
    size: 16px;
  }
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: color 200ms ease-in-out, margin $navbar-transition;

  .navbar-inverse & {
    @media (min-width: $grid-float-breakpoint) {
      margin-top: ($navbar-inverse-height - 2 * $navbar-padding-vertical - $line-height-computed) / 2;
    }
  }
}


.navbar-brand {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: height $navbar-transition;

  .navbar-inverse & {
    @media (min-width: $screen-sm-min) {
      height: $navbar-inverse-height;
    }
  }

  img {
    height: $navbar-default-logo-height;
    transition: height $navbar-transition;

    .navbar-inverse & {
      @media (min-width: $screen-sm-min) {
        height: $navbar-inverse-logo-height;
      }
    }
  }
}


.navbar-toggle {
  @include navbar-vertical-align($navbar-toggle-height);
  transition: margin $navbar-transition;

  .navbar-inverse & {
    @media (min-width: $screen-sm-min) {
      margin-top: ($navbar-inverse-height - $navbar-toggle-height) / 2;
    }
  }
}


.hamburger {
  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }

  .hamburger-inner {
    .navbar-inverse & {
      &,
      &::before,
      &::after {
        background-color: $navbar-inverse-toggle-icon-bar-bg;
      }
    }
  }
}


.nav > .navbar-add {
  display: flex;
  flex-direction: column;
  height: $navbar-height;
  justify-content: center;
  padding: 0 $navbar-padding-horizontal;
  transition: height $navbar-transition;

  .navbar-inverse & {
    @media (min-width: $screen-sm-min) {
      height: $navbar-inverse-height;
    }
  }
}