.buy-now {
  text-align: center;
}

.buy-now__link {
  background-color: #89c831;
  border-radius: 0.5rem;
  color: white !important;
  display: inline-block;
  font-weight: bold;
  padding: 0.5rem 2rem;
  position: relative;

  &::after {
    border-left: 65px solid transparent;
    border-right: 65px solid transparent;
    border-top: 10px solid #89c831;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% - 1px);
  }
}
