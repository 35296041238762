/*----- COMMON -----*/

body {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Oswald", sans-serif;
  line-height: 120%;
}
h1 {
  color: #fff;
  font-size: 44px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-shadow: 1px 1px 2px #000;
}
h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
h3 {
  font-size: 20px;
}

input[type=text], textarea {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cccec0;
  border-radius: 0;
  line-height: 100%;
  padding: 6px 3px;
  width: 100%;
}
input:focus, textarea:focus {
  outline: none;
}

label:empty {
  display: none;
  margin-bottom: 0;
}


/*----- CONTAINERS -----*/

/* header */

.title-links {
  text-align: center;
}
.title-links ul {
  margin: 0;
  padding: 0;
}
.title-links li {
  color: #fff;
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 17px;
  font-weight: 400;
  list-style: none;
  margin: 0 10px;
  text-transform: uppercase;
}
.title-links a {
  border-bottom: 2px solid #fd8000;
  color: inherit;
  cursor: pointer;
  line-height: 180%;
}
.title-links a:active,
.title-links a:focus,
.title-links a:hover {
  text-decoration: none;
}

/* article */

article {
  padding: 40px 0;
}
.section h2 {
  color: #89c831;

  &.centered {
    color: inherit;
    text-align: center;
  }
}
article h2:first-child {
  margin-top: 0;
}
article a {
  border-bottom: 1px solid #fd8000;
  color: inherit;
  text-decoration: none;
}
article a:focus,
article a:hover {
  color: #fd8000;
  outline: none;
  text-decoration: none;
}

/* section */

section.selected-products,
section.related-products {
  padding: 40px 0;
}
section.related-products h2,
section.selected-products h2 {
  margin-top: 0;
}

/* footer */

footer {
  background-color: #40535a;
  color: #fff;
  font-weight: 300;
  padding: 40px 0;
}
footer h3 {
  font-weight: 500;
  line-height: 100%;
  margin: 0 0 20px 0;
}
footer b {
  font-weight: bold;
}

.footer-nav {
  margin-bottom: 20px;
  padding: 0;
}
.footer-nav > li {
  list-style: none;
}
.footer-nav > li > a {
  color: inherit;
  display: block;
  line-height: 100%;
  padding: 0 0 10px 0;
}

.footer-help-img {
  margin-bottom: 40px;
}

footer .form_submit .btn {
  width: 100%;
}

.copyright {
  font-size: 12px;
  padding-top: 40px;
}
.copyright a {
  color: inherit;
  text-decoration: underline;
}


/*----- ELEMENTS -----*/

/* alert */

.alert {
  border-radius: 0;
  padding: 10px 15px;
}

/* color */

.color1 {
  color: #89c831;
}

/* button */

.btn {
  font-family: "Oswald", sans-serif;
}

.btn-primary {
  background-color: #89c831;
  border-color: #89c831;
}
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #6a9a25;
  border-color: #6a9a25;
}

.btn-lg {
  padding: 10px 40px;
}

/* form */

.form_error,
.form_field_error {
  color: #a94442;
}
.form_error {
  font-size: 14px;
}
.form_field_error {
  font-size: 12px;
  padding: 2px 0 0 4px;
}

.form_field {
  margin-bottom: 15px;
}

.form_field_with_errors input[type=text],
.form_field_with_errors textarea {
  border-color: #a94442;
}

/* modal */

.modal-header {
  border-bottom: 0;
  padding: 30px;
  padding-bottom: 0;
}
.modal-header .close {
  background: url("/assets/icon-letter-x.svg") no-repeat 50% 50%;
  background-size: 22px 22px;
  height: 22px;
  opacity: 1;
  position: absolute;
  right: -35px;
  text-shadow: initial;
  top: 2px;
  width: 22px;
}
.modal-header .close:focus {
  outline: none;
}
.modal-header .intro {
  margin-top: 5px;
}
.modal-title {
  color: #89c831;
}
.modal-content {
  border-radius: 0;
}
.modal-body {
  padding: 30px;
}


/*----- PAGES -----*/

/* front page */

.front-page header .container {
  position: relative;
  z-index: 1;
}
.front-page header h1 {
  color: #89c831;
  font-size: 50px;
  text-transform: uppercase;
}
.front-page section:nth-child(even) {
  background-color: #d7dfe2;
}
.front-page section:nth-child(odd) {
  background-color: #fff;
}
.front-page section.selected-products {
  padding: 40px 0 0 0;
}
.front-page section h2 {
  margin-top: 0;
}

/* blog */

.blog-grid {
  cursor: pointer;
  margin-bottom: 25px;
}
.blog-grid h3 {
  color: #e16b0b;
  line-height: 120%;
  margin: 14px 0 0 0;
}
.blog-grid:hover img {
  box-shadow: 0 0 0 6px #99ce4a;
}
.blog-grid:hover h3 {
  color: #99ce4a;
}

.blog-older,
.blog-newer {
  margin-top: 20px;
}
.blog-newer {
  text-align: right;
}

/* client stories, solutions */

.story,
.solution {
  cursor: pointer;
  margin-bottom: 25px;
  position: relative;
}
.story:hover,
.solution:hover {
  box-shadow: 0 0 0 6px #99ce4a;
}
.story-title,
.solution-title {
  color: #fff;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  text-align: center;
  text-shadow: 1px 1px 2px #000;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.story-modal .modal-header,
.solution-modal .modal-header {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 0;
  height: 230px;
  padding: 0;
  position: relative;
}
.story-modal .modal-title,
.solution-modal .modal-title {
  background-color: rgba(0,0,0,0.4);
  bottom: 0;
  color: #fff;
  left: 0;
  line-height: 120%;
  padding: 15px 30px;
  position: absolute;
  text-shadow: 1px 1px 2px #000;
  width: 100%;
}


/*----- MEDIA SPECIFIC -----*/

/* xs */
@media (max-width: 767px) {
  .modal-header .close {
    background-color: rgba(0,0,0,0.2);
    right: 0;
    top: 2px;
    width: 32px;
    height: 32px;
  }

  .story-modal .close,
  .solution-modal .close {
    background-color: rgba(0,0,0,0.4);
  }
}

/* sm */
@media (min-width: 768px) and (max-width: 991px) {
  .front-page header h1 {
    font-size: 60px;
  }
}

/* >= sm */
@media (min-width: 768px) {
  .title-links ul {
    display: inline;
  }
  .title-links li {
    display: inline;
    margin: 0 40px;
  }

  .story-modal .modal-header,
  .solution-modal .modal-header {
    background-size: auto;
    height: 350px;
  }
}

/* >= md */
@media (min-width: 992px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 24px;
  }

  /*.navbar-default .navbar-nav > li > a {
    font-size: 20px;
    line-height: 65px;
    padding: 0 15px 0 40px;
  }
  .navbar-default .navbar-nav > li:first-child > a {
    background-image: url("/assets/icon-avatar.svg");
  }
  .navbar-default .navbar-nav > li:last-child > a {
    background-image: url("/assets/icon-phone-call.svg");
  }

  .navbar-logo .img {
    height: auto;
    margin-top: 3px;
  }

  .btn-offcanvas-open {
    background-position: 30px 50%;
    height: 65px;
    line-height: 65px;
    padding: 0 30px 0 80px;
  }*/

  .title-links li {
    margin: 0 60px;
  }

  .modal-dialog {
    margin-top: 45px;
  }

  .front-page header h1 {
    font-size: 96px;
  }
}

/* lg */
@media (min-width: 1200px) {
  body {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .modal-open .navbar-fixed-top {
    margin-right: 15px;
  }
}

.form_fields .tootenimi {
  display: none;
}
