.table {
  margin: 5rem 0;
}


.table thead {
  tr {
    background-color: #40535a;
    border-bottom: 4px solid white;
  }

  img {
    height: 30px;
  }
}


.table {
  a {
    color: $text-color !important;
    cursor: pointer;

    &:hover {
      border-bottom: 2px solid $brand-success;
      text-decoration: none !important;
    }
  }
}


.table .sortable {
  cursor: pointer;

  a {
    position: relative;

    &:hover {
      border: 0;
    }
  }

  &:not(.ascending) a::before {
    border-bottom: 5px solid $gray-light;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: '';
    height: 0;
    margin-left: 5px;
    left: 100%;
    position: absolute;
    top: 5px;
    width: 0;
  }

  &:not(.descending) a::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $gray-light;
    bottom: 5px;
    content: '';
    height: 0;
    margin-left: 5px;
    left: 100%;
    position: absolute;
    width: 0;
  }
}