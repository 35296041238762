.custom-tooltip {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 5px;
  bottom: 100%;
  color: white;
  display: none;
  left: 50%;
  padding: 1rem;
  position: absolute;
  transform: translateX(-50%);

  a {
    color: $brand-primary !important;
  }
}

.custom-tooltip-toggle {
  border-bottom: 1px dotted $text-color;
  position: relative;

  &:hover .custom-tooltip {
    display: block;
  }
}