.container--virtuoso {
  height: 1000px;
  overflow: hidden;
  position: relative;
}

.container--virtuoso::after {
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  bottom: 0;
  content: "";
  height: 100px;
  left: 0;
  position: absolute;
  width: 100%;
}
