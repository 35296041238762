.modal-header .edy-texteditor-container {
  margin: 0 !important;
  position: static !important;
}


.modal-title {
  z-index: 100;
}


.modal-form {
  padding: 30px;
}


.modal h2:not(.modal-title) {
  color: $brand-success;
}