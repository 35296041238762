.slide {
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  white-space: normal;
  width: 100vw;

  h1 {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    transition-delay: 300ms;
  }

  h2 {
    color: white;
    opacity: 0;
    text-align: center;
    transition: opacity 300ms ease-in-out;
    transition-delay: 600ms;
  }

  .title-links {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    transition-delay: 900ms;
  }
}

.slide.active {
  h1,
  h2,
  .title-links {
    opacity: 1;
  }
}

.slides {
  font-size: 0;
  margin-top: -45px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  width: 100vw;
}

.slides-left-container,
.slides-right-container {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100px;
}

.slides-right-container {
  right: 0;
  width: 120px;
}

.slides-left,
.slides-right {
  background: transparent;
  border: solid white;
  color: white;
  font-size: 3rem;
  font-weight: bold;
  height: 30px;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  transition: opacity 300ms ease-in-out;
  width: 30px;
}

.slides-left-container:hover .slides-left,
.slides-right-container:hover .slides-right {
  opacity: 1;
}

.slides-left {
  border-width: 0 0 4px 4px;
  left: 2rem;
}

.slides-right {
  border-width: 4px 4px 0 0;
  margin-right: 1rem;
  right: 3rem;
}

.slides-container {
  transition: margin-left 300ms ease-in-out;
}

.slides-bubbles {
  bottom: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.slide-bubble {
  background-color: #d7dfe2;
  border: 2px solid $brand-success;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 .5rem;
  width: 20px;

  &.active {
    background-color: $brand-success;
  }
}