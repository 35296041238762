.about-us section {
  padding: 20px;
}


.about-us section:nth-child(even) {
  background-color: $gray-lightest;
}


.about-us .col-sm-4 {
  margin-bottom: 5rem;

  @media (max-width: $screen-sm-min) {
    margin-bottom: 1rem;
  }
}


.about-us-item {
  &,
  &:hover,
  &:active,
  &:focus {
    border-bottom: 2px solid transparent;
    color: $text-color !important;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    text-decoration: none !important;
  }

  &:hover {
    border-bottom-color: $brand-success;
  }

  img {
    flex-direction: column;
    height: 50px;
    justify-content: center;
    margin: 1rem 2rem 1rem;
    width: 50px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    margin: 0;
  }
}


.about-us-item-image-open {
  display: none;
}


.about-us-item:hover {
  .about-us-item-image-closed {
    display: none;
  }

  .about-us-item-image-open {
    display: flex;
  }
}