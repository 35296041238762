$nav-tab-item-border-width: 3px;

.nav-tabs {
  margin: {
    left: -$nav-link-padding-horizontal;
    right: -$nav-link-padding-horizontal;
  }

  li a {
    border-bottom: $nav-tab-item-border-width solid transparent;
    font: {
      size: $font-size-base;
      weight: 700;
    }
    text-transform: uppercase;

    &:hover {
      border-color: transparent;
    }
  }

  li.active a {
    &,
    &:hover,
    &:focus {
      border-color: transparent;
      border-bottom: $nav-tab-item-border-width solid $nav-tabs-active-link-hover-border-color;
    }
  }
}


.nav-tabs.nav-tabs--minimal {
  display: flex;

  > li {
    width: 50%;
  }

  li a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    text-transform: none;
    width: 100%;
  }
}


.tab-pane {
  padding: 2rem 0;
}
