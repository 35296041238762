.container.flex {
  display: flex;

  > div {
    padding: 4rem 4rem 2rem;
    width: 100%;

    &:not(:last-of-type) {
      border-right: 1px solid grey;
    }
  }
}
